<template>
  <div>
    
      <div class="idenBox">
        <p class="ideTitle">未认证,请先认证</p>
        <van-form @submit="onSubmit">
          <van-field
            v-model="fordata.truename"
            type="text"
            name="姓名"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true}]"
          />
          <van-field
            v-model="fordata.id_card"
            label="身份证号码"
            placeholder="请输入身份证号码"
            :rules="[{validator,required: true,message:'请输入正确的身份证号' }]"
          />
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
    
  </div>
</template>

<script>
import { realNameVerification } from "@/request/api";
import {  Toast } from "vant";
export default {
  data() {
    return {
      fordata: {
        truename: "",
        id_card: "",
        identificationShow: false,
      },
    };
  },
  methods: {
    validator(val) {
      return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(val);
    },
    onSubmit() {
      realNameVerification(this.fordata).then((res) => {
        if (res.code != 1000) {
          Toast.fail(res.message);
          return;
        }
        Toast.success("认证成功");
            this.$router.push(this.$route.query.redirect || "/"); // 登录成功返回进入首页页面/
        this.$store.dispatch("info");
      });
    },
  },
};
</script>

<style scoped>
.idenBox{
  padding: 20px;
  border-radius: 5px;
}
.idenBox .ideTitle{
  text-align: center;
  font-size: 18px;
}
.fonter{
  float: right;
}
</style>