<template>
  <div>
    <div>
      <div class="popupE"></div>
      <div class="swiperBox">
        <p class="texter">点击右上角选择【发送给朋友】或【分享到朋友圈】</p>
        <van-swipe
          :loop="true"
          :width="300"
          :show-indicators="false"
          style="opacity: -999"
        >
          <template>
            <van-swipe-item
              class="boepoa"
              v-for="(item, index) in listArr"
              :key="index"
              :id="`imgContainer_${index}`"
            >
              <div class="boeWidth">
                <img :src="item.img" />
                <div class="marBottom">
                  <div class="boeprac">
                    <p class="titlea">长按保存，扫码分享</p>
                    <p class="titletext">{{ infoArr.nickname }}</p>
                  </div>
                  <div class="riherwm">
                    <img class="imgaer" :src="infoArr.code_oss" />
                  </div>
                </div>
              </div>
            </van-swipe-item>
          </template>
        </van-swipe>
        <div class="imgBox">
          <div class="box">
            <div class="box1" v-for="(item1, index1) in listImg" :key="index1">
              <img :src="item1.img" alt="" />
            </div>
          </div>
        </div>
        <van-icon name="cross" class="icondace" @click="getShow" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import html2canvas from "html2canvas";
export default {
  name: "Extension",
  props: {
    list: {
      type: Array,
    },
  },
  computed: {
    ...mapState(["infoArr"]),
  },
  data() {
    return {
      // ShowPopup:false,
      listArr: [
        { id: "1", img: require("@/assets/img/poster1.png") },
        { id: "2", img: require("@/assets/img/poster2.png") },
      ],
      listImg: [],
      hezIs: true,
    };
  },
  mounted() {
    this.createCanvas();
  },
  methods: {
    getShow() {
      this.$emit("getShow");
    },
    createCanvas() {
      let img_arr = [];
      this.listArr.forEach((item, index) => {
        var key = "imgContainer_" + index;
        var box = document.getElementById(key);
        let url = "";
        html2canvas(box, {
          backgroundColor: null,
          useCORS: true,
          allowTaint: false,
          letterRendering: true,
          windowHeight: 350,
          height: 350,
          windowWidth: 270,
          width: 270,
          y: -1,
        }).then((canvas) => {
          url = canvas.toDataURL("image/png");
          img_arr.push({ img: url });
          this.listImg = img_arr;
          this.hezIs = false;
        });
      });
    },
  },
};
</script>

<style scoped>
.swiperBox {
  width: 100%;
  position: fixed;
  left: 50%;
  top: 41%;
  z-index: 101;
  transform: translate(-50%, -50%);
}
.swiper-container {
  touch-action: none;
}
.popupE {
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
}
.boeWidth img {
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.boepoa img {
  width: 240px;
  height: 272px;
}
.texter {
  font-size: 14px;
  color: #fff;
  padding: 10px 45px;
}
.marBottom {
  width: 240px;
  margin: auto;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}
.riherwm {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 60px;
  height: 60px;
}
.boeprac {
  padding: 20px 10px;
}
.titlea {
  padding-bottom: 3px;
}
.imgaer {
  width: 60px !important;
  height: 60px !important;
}
.boeprac .titlea {
  font-size: 12px;
  font-weight: bold;
}
.boeprac .titletext {
  font-size: 10px;
  color: #000a33ff;
}
.icondace {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, 0);
  font-size: 20px;
}
.imgBoer {
  height: 350px;
  width: 270px;
  margin: auto;
}
.imgBox {
  width: 100%;
}
.imgBox img {
  width: 100%;
  float: left;
  height: 10rem;
}
.imgBox {
  overflow: hidden;
  height: 118px;
}
.imgBox .box {
  padding: 10px 0;
  white-space: nowrap; /*文本不会换行，文本会在在同一行上继续*/
  overflow-y: auto; /*可滑动*/
  position: absolute;
  top: 1rem;
}
/*自定义滚动条的伪对象选择器, CSS 可以隐藏滚动条*/
/*.box::-webkit-scrollbar{
    display: none;
}*/
.box1:nth-last-child(1){
  margin-right: 2rem;
}
.box1 {
  width: 70%;
  display: inline-block; /*行内块元素*/
}
.box::-webkit-scrollbar {
  display: none;
}
.imgBox .box1:first-child {
  margin-left: 1.3rem;
}
</style>