<template>
  <div class="app-box">
    <div class="Index">
      <div class="headerBg cearcone">
      <div class="top">
        <div class="left">
          <img v-if="infoArr.avatar_img" :src="infoArr.avatar_img" @click="goRouter('/MySetUp')" alt="">
          <img v-else :src="GLOBAL.imgURL+'csUser.jpg'" @click="goRouter('/MySetUp')" alt="">
        </div>
        <div class="right">
          <div class="titleg" >{{infoArr.nickname}}</div>
          <div class="tmUser">{{infoArr.role_name}}
            <span v-if="infoArr.id_card == null||infoArr.id_card =='' && infoArr.id_card == null||infoArr.id_card ==''" class="authentication authentication1">未认证</span>
            <span v-else class="authentication authentication1">已认证</span>
          </div>
        </div>
        <!-- end icon -->
        <div class="setIcom" @click="goRouter('/MySetUp')"></div>
      </div>
      <!-- end -->
      <div class="bottom">
        <div class="bottLeft">
          <!-- 显示 -->
          <div class="leftBottom" v-show="money" >
            <span @click="goRouter('/Userincome')">{{infoArr.balance}}</span>
            <i class="iconfont icon-xianshi iconFont" @click="moneyShow"></i>
          </div>
          <!-- 隐藏 -->
          <div class="leftBottom" v-show="!money">
            <span class="iojef">*******</span>
            <img class="iconFont iconFont1" src="../../../src/assets/icon/monyed.png" alt="" @click="moneyShow">
          </div>
        <div class="text">累计收入</div>
        <div class="text text1" @click="goRouter('/Userincome')"><span style="margin-right:.1rem">*</span>可提现{{infoArr.balance}}</div>
        </div>
        <div class="bottRight" >
         <div class="leftBottom" @click="goRouter('/Userincome')" v-if="infoArr.month_income == 0">{{infoArr.month_income}}</div>
         <div class="leftBottom" @click="goRouter('/Userincome')" v-else>+{{infoArr.month_income}}</div>
         <div class="text" @click="goRouter('/Userincome')">本月收入</div>
         <van-button round type="default" size="small" @click="sucnscc"><span class="colorfga" >提现</span></van-button>
        </div>
      </div>
    </div>
    </div>
    <!-- end 头部结束-->
    <!-- end 分类开始 -->
    <div class="typeBox">
      <div class="li imgicom1" @click="goRouter('/MyCustomer')">
        <div class="title">我的客户(人) <i class="iconfont icon-right iconLi"></i></div>
        <p class="textNnm">{{infoArr.customers_total}}</p>
      </div>
      <div class="li imgicom2 rightAu" @click="goRouter('/MyTeam')">
        <div class="title">我的团队 (人)<i class="iconfont icon-right iconLi"></i></div>
        <p class="textNnm">{{infoArr.member_total}}</p>
      </div>
    </div>
    <!-- end结束 -->
    <div class="typeBox typeBoxImg">
      <div class="li leftImg " @click="Jmei">
        <p class="introduction">项目介绍</p>
      </div>
      <!-- <div class="li rightImg1 rightAu" @click="goRouter('/Friends')">
        <p class="introduction introduction2">发圈素材</p>
      </div> -->
      <div class="li rightImg rightAu" @click="swipeGet">
        <p class="introduction introduction1">项目推广</p>
      </div>
    </div>
    <!-- end 招商培训-->
    <div class="trainBox" id="posterHtml" @click="goRouter('/CompanyIntroductionTrain')">
      <div class="trainUl">
        <h4>招商培训</h4>
        <img class="banBg" :src="GLOBAL.imgURL + 'home_merchant.png'" alt="">
      </div>
    </div>
    <!-- end提现失败 -->
    <div class="popupE" v-show="errorShow"></div>
    <div class="successBox successBox1 animate__animated  animate__shakeY" v-show="errorShow">
        <div>
            <div class="textTel textTel1">
          <van-button type="info" class="seanns"  size="normal" @click="identification">去设置</van-button>
            </div>
        </div>
        <van-icon name="cross" class="icondace" @click="errorShow = false" />
    </div>
     <div v-if="swipeBox"> 
       <Extensionswipe @getShow="getShow" />
     </div>
        <van-popup
          v-model="identificationShow"
          :style="{ height: '40%', width: '90%' }"
          :close-on-click-overlay="false"
          >
          <van-icon name="cross" class="floatright" @click="identificationShow = false" />
          <Investment></Investment>
      </van-popup>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import  Extensionswipe  from '@/components/Extension/Extension.vue'
import Investment from "@/components/Authentication/Authentication"
import {setItem,getItem} from "@/utils/storage"
export default {
  components: {
    Extensionswipe,
    Investment
  },
  name: "Index",
  data(){
    return{
        money:getItem('money'),
        swipeBox:false,
        errorShow:false,//提现失败显示
        identificationShow:false,
    }
  },
  computed:{
     ...mapState(['infoArr'])
  },
  created() {
    this.info();
  },
  methods: {
     info(){
       this.$store.dispatch('info')
    },
    moneyShow(){
      this.money = !this.money;
      setItem('money',this.money)
    },
    sucnscc(){
      if(this.infoArr.id_card==null||this.infoArr.id_card==''&&this.infoArr.truename==null||this.infoArr.truename==''){
        this.errorShow = true;
        let path = this.$router.history.current.path;
        this.$router.push({ path, query: {redirect:'/Userincome'}});
      } else{
        this.$router.push('/Userincome');
      }
    },
    identification(){
      this.errorShow = false;
      this.identificationShow = true;
    },
    swipeGet(){
      this.swipeBox = true;
    },
    getShow(){
      this.swipeBox = false;
    },
    Jmei(){
      window.location.href="https://mp.weixin.qq.com/s/W6kZRWBvnnx2WdrKVzNiQA";
    }
  },
};
</script>
<style scoped>
.app-box {
    min-height: 100vh;
    background-color: #efeff4;
    box-sizing: border-box;
    margin-bottom: 50px;
  }
.Index{
background: url(../../../src/assets/img/head_bg.jpg) no-repeat;
background-size: 100% 100%;
}
.cearcone{
padding: 15px 10px;
box-sizing: border-box;

}
.headerBg{
  width: 100%;
  overflow: hidden;
  
  /* background: red; */
  background-size: 100% 100%;
}
.headerBg .top{
  overflow: hidden;
  position: relative;
}
.headerBg .left{
border-radius: 50%;
overflow: hidden;
  width: 45px;
  height: 45px;
  float: left;
}
.headerBg .left img{
width: 100%;
}
.headerBg .right{
  margin-left: 50px;
  color: #fff;
}
.authentication{
  font-size: 10px;
  background:#6782F2FF;
  padding: 3px 4px;
  border-radius: 3px;
  margin-left: 5px;
}
.titleg{
  font-size: 16px;
  margin-bottom:3px;
}
.tmUser{
  font-size: 15px;
}
.setIcom{
  width: 20px;
  height: 20px;
  background:url(../../../src/assets/icon/setUp.png) no-repeat;
  background-size: 100%;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
.bottom{
margin-top: 10px;
display: flex;
}
.bottom .bottLeft{
  flex: 3;
}
.bottom .bottRight{
  flex: 2;
  text-align: right;
}
.bottom .text{
  font-size: 15px;
  color: #fff;
}
.bottom .leftBottom{
  font-size: 30px;
  color: #fff;
  position: relative;
}
.text1{
  margin-top: 20px;
}
.leftBottom .iconFont{
  font-size: 32px;
  margin-top: .1rem;
}
.bottRight .van-button{
  padding: 0 30px;
  margin-top: 10px;
}
.typeBox{
  padding: 10px 10px;
  box-sizing: border-box;
  display: flex;
}
.typeBox .li{
  flex: 1;
  border-radius: 10px;
  padding: .4rem .2rem;
  padding-right: 5px;
  position: relative;
}
.typeBox .li .title{
  font-size: .4rem;
}
.typeBox .li .iconLi{
  font-size: 20px;
  color: #8C8EA0FF;
  vertical-align:middle;
  float: right;
}
.rightAu{
  margin-left: 10px;
}
.li .textNnm{
  font-size: 25px;
  font-weight: bold;
  margin-top: 8px;
}
.colorfga{
  color: #0E8AFFFF;
  font-size: 16px;
}
.typeBoxImg{
  padding:0 10px;
}
.typeBoxImg .li{
  /* height: 210px; */
  overflow: hidden;
  min-height: 5rem;
}
.typeBoxImg .leftImg{
background: url(../../../src/assets/img/introduction.png) no-repeat;
background-size: 100% 100%;
}
.typeBoxImg .rightImg1{
background: url(../../../src/assets/img/hair.png) no-repeat;
background-size: 100% 100%;
}
.typeBoxImg .rightImg{
background: url(../../../src/assets/img/extension.png) no-repeat;
background-size: 100% 100%;
}
.imgicom1{
  background: url(../../../src/assets/img/imfkle.png) no-repeat;
  background-size: 100% 100%;
}
.imgicom2{
  background: url(../../../src/assets/img/rimrile.png) no-repeat;
  background-size: 100% 100%;
}
.trainBox{
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
}
.trainUl{
  background: #fff;
}
.trainUl h4{
  font-size: .45rem;
  margin: .1rem 0;
}
.trainUl{
  padding: 5px 10px;
  border-radius: 10px;
}
.trainUl h4::before{
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  background: #4163EEFF;
  vertical-align: bottom;
  margin-right: 5px;
  border-radius: 3px;
}
.trainUl .banBg{
  width: 100%;
  padding: 5px 0;
}
.iconFont1{
  display: inline;
  width: 20px;
  height: 20px;
  position: absolute;
}
.iojef{
  margin-right: 5px;
}
.popupE {
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
}
.successBox{
    position: fixed;
    left: 15%;
    top: 30%;
    width: 270px;
    height: 273px;
    transform: translate(-50%,-50%);
    z-index: 101;
    background: url(../../assets/img/withdrawalscor.png);
    background-size: 100% 100%;
}
.textTel{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    width: 100%;
    font-size: 14px;
    text-align: center;
    line-height: 35px;
}
.textTel1{
    bottom: 15px;
}
.icondace {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, 0);
  font-size: 20px;
}
.successBox1{
    background: url(../../assets/img/error.png);
    background-size: 100% 100%;
    left: 15%;
    top: 30%;
}
.textTel1 ::v-deep .seanns{
border-radius: 10px;
padding: 0 70px;
}
.floatright{
  position: absolute;
  top: 10px;
  right: 10px;
}
.popur ::v-deep.van-popup{
  position: relative;
}
.authentication1{
  background: #3EA2FFFF !important;
}
.introduction{
  position: absolute;
  left: 50%;
  bottom: .8rem;
  transform: translate(-50%,0);
  font-size: .4rem;
  color: #56B9FAFF;
  background: #fff;
  padding: .2rem .3rem;
  border-radius: 20px;
  width: 2rem;
  text-align: center;
}
.introduction1{
  color:#FF9971FF ;
}
.introduction2{
  color: #787DFFFF;
}
</style>